import React from 'react';
import appSlider from 'assets/images/app-slider.mp4';
import cleanLikeBoss from 'assets/images/clean-like-boss.png';
import cleanLikeBossWebp from 'assets/images/clean-like-boss.png?as=webp';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';
import { SUPPORTED_REGIONS } from '../../../shared/enums';
import { getPerfectSquare } from '../../../shared/utils';
import { selectLocalizationData } from '../../features/localization/localizationSlice';

const FadeIn = loadable(() => import('../common/FadeIn'));

/**
 * @returns {JSX.Element}
 * @constructor
 */
const FeaturedBlock = () => {
  const localization = useSelector(selectLocalizationData);
  const measure = [ SUPPORTED_REGIONS.US, SUPPORTED_REGIONS.CA ].includes(localization.region) ? 'ft' : 'm';

  return (
    <section className='feature-block'>
      <div className='container'>
        <div className='feature-block__item feature-block__item--video feature-block--reverse'>
          <figure className='feature-block__image feature-block__image--animate1'>
            <FadeIn>
              <video
                id='feature-block__slider-video'
                playsInline
                webkit-playsinline='true'
                autoPlay
                muted
                loop
              >
                <source src={appSlider} type='video/mp4' />
              </video>
            </FadeIn>
          </figure>
          <div className='feature-block__content'>
            <h2 className='feature-block__title'>No, you don’t have to take our word for it.</h2>
            <h3 className='feature-block__subtitle'>
              The biggest problem with air purifiers is that you don’t know if
              they’re actually doing anything. There’s no proof you can see.
            </h3>
            <div className='feature-block__description'>
              <p>
                Mila solves that. Simply adjust the fan speed to your desired air quality and Mila shows you the
                estimated time to achieve it. It’s as intuitive as setting your thermostat.
              </p>
            </div>
          </div>
        </div>
        <div className='feature-block__item feature-block--large-image'>
          <figure className='feature-block__image feature-block__image--animate2'>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source srcSet={cleanLikeBossWebp} type='image/webp' />
                  <source srcSet={cleanLikeBoss} type='image/png' />
                  <img src={cleanLikeBoss} alt='Basic Breather and Big Sneeze filters' />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <div className='feature-block__content feature-block__content--last'>
            <h2 className='feature-block__title'>Of course, she cleans like a boss too.</h2>
            <h3 className='feature-block__subtitle'>
              Packed with
              {' '}
              {getPerfectSquare(45, localization.region, measure)}
              {' '}
              {measure}
              <sup>2</sup>
              {' '}
              of certified HEPA and a powerful clean
              air delivery rate (CADR), Mila’s got both the brains and the brawn.
            </h3>
            <Link to='/performance' className='btn btn--large'>Learn More</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedBlock;
